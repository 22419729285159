import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import BoardContainer from "./BoardContainer";

function HoloBoard() {
  return (
    <div>
      <BoardContainer ts=""/>
    </div>
  );
}

export default HoloBoard;