import React, {Component} from 'react';
import axios from 'axios';

const YouTubeMember = props => (
    <tr >
        <td >{props.member.rank}</td>
        <td >{props.member.name}</td>
        <td >{Number(Math.round(Number(props.member.sct)/1000)*1000).toLocaleString()}</td>
        <td >{Number(Math.round(Number(props.member.ttf)/1000)*1000).toLocaleString()}</td>
        <td >{Number(Math.round(Number(props.member.cvc)/1000)*1000).toLocaleString()}</td>
        <td >
            <a href={'https://www.youtube.com/channel/' + props.member.ytid}>Youtube</a> | <a href={'https://www.twitter.com/' + props.member.ttid}>Twitter</a>
        </td>
    </tr>
)

const RetiredMember = props => (
    <tr >
        <td >{props.member.rank}</td>
        <td >{props.member.name} (R)</td>
        <td >–</td>
        <td >–</td>
        <td >–</td>
        <td >–</td>
    </tr>
)

export default class BoardContent extends Component {
    constructor(props){
        super(props);
        console.log(process.env.NODE_ENV)
        console.log(process.env.REACT_APP_a)
        this.state = {page:props.page, members:[]};
        setInterval(() => {
            console.log("\n\nLeaderboard Updated.");
            this.update();
        }, 1000 * 60 * 5);
    }

    componentDidMount(){
        this.update();
    }

    memberList() {
        return this.state.members.map(currentMember => {
            if (Number(currentMember.sct) > 0){
                if (currentMember.ytid){
                    return <YouTubeMember member={currentMember} key={currentMember._id}/>
                }
            }
            else{
                return <RetiredMember member={currentMember} key={currentMember._id}/>
            }
        })
    }

    update(){
        axios.get('https://immense-mesa-71168.herokuapp.com/members?page=' + (this.state.page + 1) + '&limit=10')
        .then(response => {
            this.setState({members: response.data});
            this.render();
        })
        .catch((error) => {
            console.log(error);
        })
    }
    
    render(){
        return (
            <div >
                <table className="table">
                    <thead >
                        <tr >
                            <th >Rank</th>
                            <th >Name</th>
                            <th >Subscribers</th>
                            <th >Followers</th>
                            <th >Views</th>
                            <th >Links</th>
                        </tr>
                    </thead>
                    <tbody >
                        {this.memberList()}
                    </tbody>
                </table>
            </div>
        )
    }
}