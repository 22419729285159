import BoardContent from "./BoardContent";
import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import "../css/embla.css";

const DotButton = ({ selected, onClick }) => (
  <button
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  />
);

const Board = ({ slides }) => {
  const [viewportRef, embla] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className="regcontainer">
        <div className="row">
          <div className="col-lg">
              <div className="embla">
                <div className="embla__viewport" ref={viewportRef}>
                  <div className="embla__container">
                    {slides.map((index) => (
                    <div className="embla__slide" key={index}>
                      <BoardContent page={index}/>
                    </div>
                    ))}
                  </div>
                </div>
              </div>
          </div>    
        </div>
        <div className="row">
          <div className="col-lg"><div className="pagedots"><div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
            ))}
            </div></div></div>
        </div>
        <div className="row">
          <div className="col-lg"><div className="twitterlink"><a href='https://www.linkedin.com/in/na2le/'>LinkedIn</a></div></div>
        </div>
        <div className="row">
          <div className="col-lg"><div className="footnote">
          Information displayed are provided by the official YouTube/Twitter APIs. All values are rounded to the closest thousand.<br/>
          Ranking is primarily based on the number of subscribers each member has on YouTube.<br/>
          The remaining statistics are used as tiebreakers when necessary in the order presented.
          Data is auto-refreshed every 5 minutes.
          </div></div>
        </div>
        <div className="row">
          <div className="col-lg">
            <div className="disclaimer">
              Disclaimer: This is an unofficial, not-for-profit fan project. There is no affiliation or association, implied or otherwise, between this website and COVER Corporation in any capacity.<br/>
              This product is provided "AS IS" without any warranty whatsoever.
              We will not be liable to you or anyone else for any damages whatsoever, regardless of cause, <br/>arising out of or in connection with your use or access of this website.<br/><br/>
              Hololive Production, Hololive, Hololive CN, Hololive ID, Hololive EN, Holostars, and INNK Music are registered trademarks of COVER Corporation and referenced in fair use.<br/>
              YouTube is a registered trademark of Google, LLC and referenced in fair use.
              Twitter is a registered trademark of Twitter, Inc. and referenced in fair use.<br/>
              All information displayed are in the public domain. No copyright infringement intended.<br/>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Board;