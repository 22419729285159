import React, {Component} from "react";
import Board from "./Board";

import "../css/base.css";
import "../css/reset.css";
import "../css/header.css";
import "../css/footer.css";

const http = require('http');
const SLIDE_COUNT = 6;
const slides = Array.from(Array(SLIDE_COUNT).keys());

class BoardContainer extends Component {
  constructor(props){
    super(props);
    this.state = {ts:props.ts};
    setInterval(() => {
       this.update();
    }, 1000 * 60 * 5);
    this.update();
}

update(){
    let url = `https://immense-mesa-71168.herokuapp.com/members/lastupdate`;

    http.get(url,(res) => {
        let body = "";
        res.on("data", (chunk) => {
            body += chunk;
        });

        res.on("end", () => {
            try {
                let json = JSON.parse(body);
                var newts = json.ts;
                this.setState({ts: newts});
                this.render();
            } catch (error) {
                console.log(error.message);
            };
        });

    }).on("error", (error) => {
        console.log('API [SCT] ERROR: ' + error.message);
    });
}

render(){
    return (
      <main>

<div className="topcontainer">
        <div className="row">
          <div className="col-lg">
              <div name="headergroup">
                  <div className="row">
                  <div className="col-lg">
                      <div className="title">
                                <div className="row">
                                    <a className="header" href="https://t.ly/1Evg">HoloBoard</a>
                                </div> 
                        </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg">
                        <div className="headergroup">
                              <div> <span><div class="tagline">A simple audience tracker for hololive!</div></span></div>
                              <div> <span><div class="updateline">Last Updated: UTC {this.state.ts}</div></span></div>
                        </div>
                    </div>
                  </div>      
            </div>
          </div>    
        </div>
    </div>
    <Board slides={slides} />
    </main>
    )
  }
}

export default BoardContainer;